import { render, staticRenderFns } from "./PaymentGroupsAgencyComponent.vue?vue&type=template&id=05e35634&scoped=true&"
import script from "./PaymentGroupsAgencyComponent.vue?vue&type=script&lang=js&"
export * from "./PaymentGroupsAgencyComponent.vue?vue&type=script&lang=js&"
import style0 from "./PaymentGroupsAgencyComponent.vue?vue&type=style&index=0&id=05e35634&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e35634",
  null
  
)

export default component.exports