<template>
  <div class="modal-card modal-form-group rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal" v-if="post">{{ $t('add_payment_group') }}</div>
          <div class="title-new-modal" v-else>{{ $t('edit_payment_group') }}</div>
          <button @click="$emit('close')" class="delete" aria-label="close"></button>
        </div>
        <section>
          <div class="card rounded-10">
            <b-tabs type="is-boxed">
              <b-tab-item>
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">{{ $t('data') }}</span>
                </template>
                <div class="columns is-flex">
                  <div class="column is-3">
                    <div class="boxed">
                      <div class="head is-flex is-align-items-center is-justify-content-center">
                        <b-icon type="is-white" icon="information"></b-icon>
                        <div class="px-4 info">{{ $t('info') }}</div>
                      </div>
                      <div class="body">
                        <p class="text">
                          {{ $t('payment_g_text') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <template v-if="user.userType === userTypes.MANAGER">
                      <div class="columns">
                        <div class="column is-full">
                          <b-field>
                            <b-field :label="`${$t('payment_groups')}*`">
                              <multiselect
                                  v-model="payment"
                                  :custom-label="currencyFromTo"
                                  open-direction="bottom"
                                  :show-no-results="false"
                                  :options="list">
                              </multiselect>
                            </b-field>
                          </b-field>

                        </div>
                      </div>
                      <hr>
                    </template>

                    <div class="columns">
                      <div class="column is-full">
                        <b-field :label="`${$t('percentage_agency')}*`">
                          <b-input custom-class="full-input" v-model="paymentGroup.agencyRate" type="number"></b-input>
                        </b-field>
                        <b-field :label="`${$t('utility_agency')}`">
                          <b-input custom-class="full-input" v-model="paymentGroup.agencyUtilityValue"
                                   type="number"></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </section>
      </div>
      <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
        <button @click="save" class="button save is-primary">{{ $t('save') }}</button>
      </div>
    </section>
  </div>

</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import {USER_TYPE} from "@/enum/userType";

export default {
  name: "modalsFormsPaymentsGroupAgency",
  components: {Multiselect},
  props: ['list', 'edit', 'item'],
  data() {
    return {
      post: true,
      payment: null,
      userTypes: USER_TYPE,
      paymentGroup: {
        agencyUtilityValue: null,
        agencyRate: null,
        paymentGroupId: null
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER'
    })
  },
  methods: {
    currencyFromTo({name, currencyFrom, currencyTo, rate}) {
      return `${name} - ${currencyFrom} — ${currencyTo} - (${rate}%)`
    },
    ...mapActions({
      savePaymentGroup: 'SAVE_PAYMENT_GROUP',
      editPaymentGroup: 'EDIT_PAYMENT_GROUP'
    }),
    save() {
      let paymentGroup = {
        agencyRate: Number(this.paymentGroup.agencyRate),
        agencyUtilityValue: Number(this.paymentGroup.agencyUtilityValue),
        type: 1,
        paymentGroupId: this.paymentGroup.paymentGroupId,
        id: null
      }
      if (this.post) {
        this.savePaymentGroup(paymentGroup)
            .then(() => {
              this.$emit('close')
              this.$emit('reload')
            })
      } else {
        paymentGroup.id = this.item.id
        this.editPaymentGroup(paymentGroup)
            .then(() => {
              this.$emit('close')
              this.$emit('reload')
            })
      }

    }
  },
  watch: {
    utilityType: function () {
      this.paymentGroup.utilityType = this.utilityType.value
    },
    payment: function () {
      this.paymentGroup.paymentGroupId = this.payment.id
    }
  },
  created() {
    if (this.edit) {
      this.post = false
      this.paymentGroup = this.item
    }
  }
}
</script>

<style scoped lang="scss">
.boxed {
  height: 250px;

  .head {
    height: 33px;
    background: #28BCE8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .info {
      font-weight: 800;
      font-size: 12px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.5px;
      color: #FFFFFF;
    }
  }

  .body {
    background: rgba(20, 204, 158, 0.2);
    margin-top: -1px;
    height: 340px;
    border-top: none;
    border-left: 6px solid #28BCE8;
    border-bottom: 6px solid #28BCE8;
    border-right: 6px solid #28BCE8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .text {
      padding: 15px;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: #0B2B46;
    }
  }
}
</style>